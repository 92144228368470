import React, { useState, useEffect } from "react";
import "./assets/style/style.scss";
import { Link, useNavigate } from "react-router-dom";

function App() {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/accueil");
    }
  }, [navigate]);

  // Gestion de la saisie des champs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch("https://api2.daurelthomas.fr/api/login_check", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: loginData.email, // Adaptation du champ pour Symfony
          password: loginData.password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Échec de la connexion");
      }

      // Stocker le token JWT dans localStorage
      localStorage.setItem("token", data.token);

      // Redirection après connexion réussie
      navigate("/accueil");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="App">
      <div className="login-page bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="bg-white shadow rounded">
                <div className="row">
                  <div className="col-md-7 pe-0">
                    <div className="form-left h-100 py-5 px-5">
                      <form className="row g-4" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label>Email<span className="text-danger">*</span></label>
                          <div className="input-group">
                            <div className="input-group-text"><i className="fa-solid fa-user"></i></div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Entrez votre email"
                              name="email"
                              value={loginData.email}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label>Mot de passe<span className="text-danger">*</span></label>
                          <div className="input-group">
                            <div className="input-group-text"><i className="fa-solid fa-key"></i></div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Entrez votre mot de passe"
                              name="password"
                              value={loginData.password}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>

                        {error && (
                          <div className="col-12">
                            <p style={{ color: "red" }}>{error}</p>
                          </div>
                        )}

                        <div className="col-sm-6">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineFormCheck" />
                            <label className="form-check-label" htmlFor="inlineFormCheck">
                              Enregistrer les informations
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <a href="#" className="float-end text-black">Contact</a>
                        </div>

                        <div className="col-12">
                          <button type="submit" className="text-white btn form-right px-4 float-end mt-4">
                            Connexion
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-md-5 ps-0 d-none d-md-block">
                    <div className="form-right h-100 text-white text-center pt-5">
                      <i className="bi bi-bootstrap"></i>
                      <h2 className="fs-1">Daurel Family</h2>
                      <p>Espace privé</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
