import { Navigate, Outlet } from "react-router-dom";

const isTokenExpired = (token) => {
  try {
    const decodedToken = JSON.parse(atob(token.split(".")[1])); 
    return decodedToken.exp * 1000 < Date.now(); // Comparer la date d'expiration avec l'heure actuelle
  } catch (error) {
    console.error("Erreur de décodage du token :", error);
    return true; // Considérer le token comme expiré en cas d'erreur
  }
};

const ProtectedRoute = () => {
  const token = localStorage.getItem("token");

  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("token"); // Supprime le token s'il est expiré
    return <Navigate to="/" replace />; // Redirige vers l'accueil
  }

  return <Outlet />;
};

export default ProtectedRoute;
